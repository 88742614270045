<template>
  <v-app >
    <v-app-bar app color="primary">
      <v-row align="center" justify="space-around">
        <vCol cols="auto" class="logo" :style="titleStyle"
          >Théorie relative de la musique</vCol
        >
        <vCol cols="auto">
          <ul>
            <li v-for="(item, i) in items" :key="i">
              <router-link :to="item.link">
                <v-icon
                  color="secondary"
                  class="mx-2"
                  :size="iconSize"
                  :title="item.title"
                  >{{ "mdi-" + item.icon }}</v-icon
                >
              </router-link>
            </li>
            <li>
              <a target="blank" :href="youtubeLink">
                <v-btn class="ml-2" color="transparent">
                  <v-img
                    :src="$store.state.dirImages + 'youtube.png'"
                    :width="iconSize"
                    title="Youtube"
                  />
                </v-btn>
              </a>
            </li>
          </ul>
        </vCol>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-container pa-8>
        <myFade>
          <keep-alive>
            <router-view />
          </keep-alive>
        </myFade>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import myFade from "@/components/myFade";
import { useWindowSize } from "@vueuse/core";
export default {
  computed: {
    titleStyle() {
      return "font-size:" + Math.min(30, this.screenWidth / 58) + "px";
    },
    iconSize() {
      return Math.min(43, this.screenWidth / 30);
    },
    screenWidth() {
      const { width, height } = useWindowSize();
      return width.value;
    },
  },
  data() {
    return {
      youtubeLink: "https://www.youtube.com/channel/UCMJlMz5iFH5S7YWO4yh0SdA",
      items: [
        {
          title: "Accueil",
          icon: "home",
          link: "/",
        },
        {
          title: "Propriétés",
          icon: "cogs",
          link: "/proprietes",
        },
        {
          title: "Gammes",
          icon: "music-clef-treble",
          link: "/gammes",
        },
        {
          title: "Jukebox",
          icon: "headphones",
          link: "/jukebox",
        },
        {
          title: "Improcessor",
          icon: "memory",
          link: "/improcessor",
        },
        {
          title: "A vous de jouer !",
          icon: "gamepad-variant",
          link: "/jouez",
        },
        {
          title: "Liens",
          icon: "account-heart",
          link: "/liens",
        },
        {
          title: "Contact",
          icon: "email",
          link: "/contact",
        },
      ],
    };
  },
  components: {
    myFade,
  },
};
</script>

<style scoped>
.logo {
  cursor: default;
  /* font-size: 1.2em; */
  font-weight: 600;
  color: silver;
}
li {
  display: inline-block;
}
</style>
