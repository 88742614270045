<template>
  <transition name="fade" :mode="mode">
    <slot />
  </transition>
</template>

<script>
export default {
  name: "myFade",
  props: {
    mode: {
      type: String,
      default: "out-in",
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>
