<template>
  <div>
    <h1>Maîtriser le mouvement par la logique des intervalles</h1>
    <vImg
      :src="dirImages + 'livre.jpg'"
      style="float: right"
      class="ml-5 mt-2"
    />
    <p>
      La théorie relative de la musique (TRM) montre qu'une erreur s'est glissée
      depuis l'origine dans l'histoire de la théorie musicale occidentale :
      avoir défini un intervalle comme une distance entre deux notes (rapport de
      hauteurs) alors qu'il devrait être compris comme un <b>mouvement</b> entre
      deux notes. Aristoxène de Tarente, disciple d'Aristote radicalement opposé
      à la doctrine pythagoricienne tournée vers l'abstraction arithmétique,
      s'approcha de très près de cette conclusion sans malheureusement y
      parvenir car la théorie musicale en eut été bouleversée depuis cette
      époque. Ce débat essentiel sur le fondement de la musique est aujourd'hui
      oublié, supposé clos puisque seul Pythagore est cité dans la grande
      majorité des ouvrages. Le dogme pythagoricien ayant été déclaré vainqueur,
      chercher à savoir par qui et pourquoi est également un sujet d'étude
      passionnant (et polémique) qui devrait faire l'objet d'un ouvrage à lui
      seul !
    </p>
    <p>
      Le propos de cette théorie est d'exprimer et exploiter la pleine puissance
      de l'intervalle avec ses trois propriétés puisqu'il s'agit d'un mouvement,
      à l'image du vecteur en mathématiques :
      <b>origine, direction, distance</b>. Et cela change tout ! Bien compris,
      l'intervalle suffit à construire par des raisonnements simples et logiques
      tout ce qui constitue une théorie musicale qui apparait complexe.
      Contrairement à la théorie classique, cette approche ne vous demandera pas
      d'admettre mais vous fera toujours comprendre. La principale difficulté
      réside dans l'habitude à prendre de raisonner non plus seulement dans
      l'absolu mais aussi <b>relativement</b> à la gamme (contexte harmonique)
      dans laquelle s'exécute une œuvre ou partie d'une œuvre, celle-ci
      exprimant un <b>point de vue</b> (métaphoriquement : lieu où vous élisez
      temporairement domicile !) mis en évidence sur le cycle des quartes /
      quintes. De la complexité introduite avec les degrés relatifs résulte une
      grande simplification pour la compréhension des concepts par la suite. La
      TRM est également <b>unificatrice</b> en renouant avec les
      <b>cultures du monde</b> basées sur la même logique, alors que la théorie
      actuelle est en rupture. Ce changement de paradigme force à rompre avec
      nos conditionnements, ce qui n'est pas aisé, mais libère une fois intégré
      par l'appréhension des véritables éléments à l'œuvre dans la création
      musicale. Le bénéfice dépasse largement d'ailleurs le cadre musical, le
      mouvement étant au cœur de tous les arts et même de toutes les
      disciplines. Lorsque l'on creuse vers l'essentiel, que trouve-t-on sinon
      le mouvement ?
    </p>
    <p>
      Ce livre s’adresse en premier lieu aux compositeurs et improvisateurs en
      quête de compréhension pour développer leur créativité. Au lieu des
      gammes, ils disposeront des outils élémentaires directement connectés aux
      émotions (éthymologiquement mouvements) que sont les intervalles. Les
      publics qui pourraient se sentir moins concernés y trouveront toutefois
      les principes logiques qui sous-tendent la théorie classique sans
      l'énoncer, une base saine qui corrige parfois des erreurs non
      négligeables, une pédagogie démystifiée de la musique susceptible
      d’éclairer et compléter leur formation.
    </p>
    <p class="mt-9">
      Commandez le livre ou téléchargez la version numérique complète sur un des
      sites suivants :
    </p>
    <vRow class="mt-n3">
      <vCol cols="auto" v-for="(marchand, i) in marchands" :key="i">
        <v-btn
          rounded
          color="secondary"
          class="primary--text"
          elevation="4"
          target="blank"
          :href="marchand.href"
          >{{ marchand.text }}</v-btn
        >
      </vCol>
    </vRow>
    <p class="mt-9">
      Aperçu des sujets développés avec la table des matières :
    </p>
    <vRow>
      <!-- <div class="d-flex" v-for="i in 2" :key="i">
        <vImg :src="dirImages + 'tdm' + i + '.png'" :style="imageHeight(i)" />
      </div> -->
      <vCol cols="auto" v-for="i in 2" :key="i">
        <vImg :src="dirImages + 'tdm' + i + '.png'" />
      </vCol>
    </vRow>
    <p class="mt-9">
      Téléchargez librement un extrait du livre comprenant les principes
      essentiels de la TRM :
    </p>
    <div id="pdftag" style="height: 88vh" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import PDFObject from "pdfobject";

export default {
  mounted() {
    PDFObject.embed("static/TRM_libre.pdf", "#pdftag");
  },
  computed: {
    ...mapState(["dirImages", "marchands"]),
  },
  components: {
    PDFObject,
  },
};
</script>

<style scoped>
p {
  text-align: justify;
}
</style>
