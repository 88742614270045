import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Proprietes from "@/views/Proprietes.vue";
import Gammes from "@/views/Gammes.vue";
import Liens from "@/views/Liens.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/proprietes",
    component: Proprietes,
  },
  { path: "/gammes", name: "Gammes", component: Gammes },
  {
    path: "/jukebox",
    name: "Jukebox",
    component: () =>
      import(/* webpackChunkName: "Jukebox" */ "../views/Jukebox.vue"),
  },
  {
    path: "/improcessor",
    name: "Improcessor",
    component: () =>
      import(/* webpackChunkName: "Improcessor" */ "../views/Improcessor.vue"),
  },
  {
    path: "/jouez",
    name: "Jouez",
    component: () =>
      import(/* webpackChunkName: "Jouez" */ "../views/Jouez.vue"),
  },
  { path: "/liens", name: "Liens", component: Liens },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
