import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dirImages: "static/images/",
    marchands: [
      {
        text: "Books On Demand (éditeur)",
        href:
          "https://www.bod.fr/livre/denis-jarrige/theorie-relative-de-la-musique/9782322076840.html",
      },
      // {
      //   text: "Cultura",
      //   href:
      //     "http://www.cultura.com/theorie-relative-de-la-musique-maitriser-le-mouvement-par-la-logique-des-intervalles-9782322076840.html",
      // },
      {
        text: "Fnac",
        href:
          "http://livre.fnac.com/a9996903/Denis-Jarrige-Theorie-relative-de-la-musique#st=th%C3%A9orie%20relative&ct=&t=p",
      },
      {
        text: "Librairie La flûte de pan",
        href:
          "http://www.laflutedepan.com/livre/50015474/denis-jarrige-theorie-relative-de-la-musique-.html",
      },
    ],
    liens: [
      {
        text: "A conversation with Henry Threadgill",
        href: "https://www.youtube.com/watch?v=42pCOwN6Uf8",
      },
      {
        text: "A la découverte de l'ancienne gamme musicale égyptienne",
        href: "http://egyptsound.free.fr/fathiVF.htm",
      },
      {
        text: "Aristoxène - Éléments harmoniques",
        href: "http://remacle.org/bloodwolf/erudits/aristoxene/table.htm",
      },
      {
        text:
          "Aristoxène de Tarente et la musique de l'Antiquité (Louis Laloy)",
        href: "https://archive.org/details/aristoxnedetare01lalogoog",
      },
      {
        text:
          "Brian Krock : Analyse de la musique de John Coltrane",
        href: "https://www.youtube.com/watch?v=DPzAvAlUJ24",
      }, 
      {
        text:
          "Carlo Rovelli : temps, espace, matière... ne sont plus ce qu'ils étaient !",
        href: "https://www.youtube.com/watch?v=YlRT8Z2cXlY",
      },
      {
        text: "Conférence philosophique sur la musique",
        href: "https://www.youtube.com/watch?v=r3yYcVsY0QM",
      },
      {
        text: "David Elbaz : Habitons-nous un trou noir ?",
        href: "https://www.youtube.com/watch?v=gEQ20WvEI10",
      },
      {
        text: "Emmanuel Bigand : l'émotion dans le langage musical",
        href:
          "http://www.college-de-france.fr/site/colloque-2008/symposium-2008-10-17-17h00.htm",
      },
      {
        text:
          "Emanuele Di Mauro : le langage DBL (degree based language)",
        href: "https://www.youtube.com/watch?v=TLWImOY1gJg&t=1215s",
      },
      {
        text: "George Russell - Lydian chromatic concept",
        href: "http://www.lydianchromaticconcept.com",
      },
      {
        text:
          "Influences croisées entre les traditions musicales perses et européennes",
        href: "http://droitcultures.revues.org/661",
      },
      {
        text:
          "Jazz et théorisation : la figure centrale de George Russell (thèse de Frédéric Saffar)",
        href:
          "http://fr.slideshare.net/fmaintenant/frdric-saffar-jazz-et-thorisation-la-figure-centrale-de-george-russell-thse-paris-8-2007",
      },
      {
        text: "Jean-Pierre Poulin (un site atypique qui renferme des perles)",
        href: "http://www.jeanpierrepoulin.com/index.htm",
      },
      {
        text:
          "John Coltrane Draws a Picture Illustrating the Mathematics of Music",
        href:
          "http://www.openculture.com/2017/04/the-tone-circle-john-coltrane-drew-to-illustrate-the-theory-behind-his-most-famous-compositions-1967.html?utm_content=buffer26c5a&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer",
      },
      {
        text: "KULTURICA - Pythagore et la musique",
        href: "http://kulturica.com/k/pythagora/pythagore-et-la-musique/",
      },
      {
        text: "L'instinct de la musique",
        href: "https://www.youtube.com/watch?v=d34FiYToYds",
      },
      {
        text: "Les Lorientales - les musiques arabes",
        href: "http://www.lorientales.com/?mode=musiques",
      },
      { text: "Maqam World", href: "http://www.maqamworld.com" },
      {
        text:
          "Mémoires concernant l'histoire, les sciences, les arts, les moeurs, les usages, &c. des Chinois (Pierre-Martial Cibot)",
        href:
          "http://www.ihoi.org/app/photopro.sk/ihoi_icono/doclist?prms_treelist=rootNode%3D1%26openNodes%3D%253A11%253A1194%253A1674%253A4%253A502%253A31%253A82%253A5205&psearch=mcpid%20%3A%20%225208%22&mcpid=5208",
      },
      {
        text: "Nassim Haramein : l'univers connecté",
        href: "https://www.youtube.com/watch?v=2qWEiyNQJgw&t=900s",
      },
    ],
  },
});

export default store;
