import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuetify from "vuetify";
Vue.use(Vuetify);
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "../public/static/css/styles.css";

const vuetifyOptions = {
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#1F04B0",
        secondary: "#eaeaea",
        info: "#4040ff",
        accent: "#f08220",
        warning: "#f08220",
        alert: "#E81214",
        error: "#E81214",
      },
      light: {
        primary: "#1F04B0",
        secondary: "#eaeaea",
        info: "#4040ff",
        accent: "#f08220",
        warning: "#f08220",
        alert: "#E81214",
        error: "#E81214",
      },
    },
  },
};
Vue.config.productionTip = false;
import store from "../public/static/js/store";
new Vue({
  router,
  store,
  vuetify: new Vuetify(vuetifyOptions),

  render: (h) => h(App),
}).$mount("#app");
