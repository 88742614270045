<template>
  <div>
    <h1>Liens</h1>
    <ul>
      <li v-for="lien in liens" style="margin-bottom: 10px">
        <a target="blank" :href="lien.href">{{ lien.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["liens"]),
  },
};
</script>

<style scoped>
a {
  padding: 0.3rem 0.5rem;
}
</style>
